import React from "react";

import Burger from "/src/components/Burger/Burger";
import Logo from "../images/Hyloq/Hyloq-white-logo (2).svg";
import FooterLogo from "../images/Hyloq/hyloq-logo-blue.svg";
import { Helmet } from "react-helmet";

const ThankYou = () => (
  <div>
    <Helmet>
      <title>Hyloq</title>
      <link
        rel="icon"
        type="image/x-icon"
        href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
      ></link>
      <link
        data-react-helmet="true"
        rel="canonical"
        href="https://hyloq.com/"
      ></link>
      <meta data-react-helmet="true" charset="utf-8"></meta>
      <meta
        data-react-helmet="true"
        name="description"
        content="Hyloq is the first true-scope geofencing advertising platform, Geofenced ads matched with unparalled data feedback for effectkive, hyperlocal marketing."
      ></meta>
      <meta
        data-react-helmet="true"
        name="robots"
        contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:locale"
        content="en-US"
      ></meta>
      <meta data-react-helmet="true" property="og:type" content="page"></meta>
      <meta
        data-react-helmet="true"
        property="og:title"
        content="Hyloq | True-Scope Geofencing Advertising Platform"
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:description"
        content="Hyloq is the first true-scope geofencing advertising platform. Geofenced ads matched with unparalled data feedback for effective, hyperlocal marketing."
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:url"
        content="https://hyloq.com/"
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:site_name"
        content="Hyloq | True-Scope Geofencing Advertising Platform"
      ></meta>
      <meta
        data-react-helmet="true"
        property="article:tag"
        content="geofencing , geofencing advertising , true-scrope"
      ></meta>
      <meta
        data-react-helmet="true"
        property="face-domain-verification"
        content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
      ></meta>
      <link
        href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
        rel="stylesheet"
      ></link>
      <script
        src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
        async
      ></script>
    </Helmet>
    <title>Hyloq | Thank You</title>
    <link
      rel="icon"
      type="image/x-icon"
      href="https://imgix.cosmicjs.com/e49a2c70-14c7-11ed-b0e6-bf31569d7c90-ICON-TRANS-03.png"
    ></link>
    <header class="bg-gradient-to-l from-secondaryBlue to-primaryBlue">
      <nav class="max-w-7xl mx-auto px-4 sm:px-6" aria-label="Top">
        <div class="w-full py-4 flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <div class="flex items-center">
            <a href="/">
              <span class="sr-only">Hyloq</span>
              <img class="h-20 w-48" src={Logo} alt="" />
            </a>
          </div>

          <button
            type="button"
            class="ml-5 inline-flex items-center justify-center rounded-md text-white hover:text-gray-200"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <Burger />
          </button>
        </div>{" "}
      </nav>
    </header>

    <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:mt-16">
      <div class="text-center">
        <img class="mx-auto w-1/3" src={FooterLogo} alt="Hyloq Logo" />
      </div>
    </main>

    <div>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:mt-16">
        <div class="text-center">
          <div className="mx-auto flex w-6/12 mb-4"></div>

          <h2 class="text-2xl md:text-4xl tracking-tight md:leading-12 font-extrabold text-blue-900 sm:text-5xl sm:leading-none md:text-6xl">
            Your Submission Was Received Successfully!
          </h2>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            One of our representatives at Hyloq will get in touch with you in
            24-48 hours if needed, thank you for your patience.
          </p>

          <div class="rounded-md">
            <div class="mt-10 rounded-md sm:flex-shrink-0 pb-40">
              <a
                href="/"
                class="w-full px-20 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-l from-secondaryBlue to-primaryBlue hover:opacity-70"
              >
                Return Home
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>

    <footer class="bg-gray-100 py-3 border-t border-gray-100">
      <div class="max-w-7xl mx-auto flex justify-between md:gap-0 md:p-4 p-2 gap-4">
        <div class="">
          <img class="h-16 w-auto" src={FooterLogo} alt="" />
          <h1 class="text-center text-xl tracking-wide font-medium text-gray-700 md:block hidden">
            Stand out in the noise.
          </h1>
        </div>

        <div class="flex items-center justify-center">
          <div class="relative">
            <h1 class="text-xl tracking-wide font-medium md:text-base text-sm text-gray-700">
              <a href="#" class="hover:opacity-80 hover:underline">
                <div class="inline-flex text-xl tracking-wide font-medium text-gray-700">
                  <svg
                    class="mr-2 h-6 w-6 pt-0.5 pb-0.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"
                      clip-rule="evenodd"
                    />
                  </svg>{" "}
                  <a href="mailto:email@example.com">
                    <h1> marketing@hyloq.com</h1>
                  </a>
                </div>
              </a>
              <br />
              <a href="#" class="hover:opacity-80 hover:underline">
                <div class="inline-flex text-xl tracking-wide font-medium text-gray-700 ">
                  <svg
                    class="mr-2 h-6 w-6 pt-0.5 pb-0.5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3.59 1.322l2.844-1.322 4.041 7.89-2.725 1.341c-.538 1.259 2.159 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396c-7.861 3.591-19.101-18.258-11.384-22.281zm1.93 1.274l-1.023.504c-5.294 2.762 4.177 21.185 9.648 18.686l.971-.474-2.271-4.383-1.026.5c-3.163 1.547-8.262-8.219-5.055-9.938l1.007-.497-2.251-4.398zm7.832 7.649l2.917.87c.223-.747.16-1.579-.24-2.317-.399-.739-1.062-1.247-1.808-1.469l-.869 2.916zm1.804-6.059c1.551.462 2.926 1.516 3.756 3.051.831 1.536.96 3.263.498 4.813l-1.795-.535c.325-1.091.233-2.306-.352-3.387-.583-1.081-1.551-1.822-2.643-2.146l.536-1.796zm.95-3.186c2.365.705 4.463 2.312 5.729 4.656 1.269 2.343 1.466 4.978.761 7.344l-1.84-.548c.564-1.895.406-4.006-.608-5.882-1.016-1.877-2.696-3.165-4.591-3.729l.549-1.841z"
                      clip-rule="evenodd"
                    />
                  </svg>{" "}
                  <a href="tel:813-597-9187">
                    <h1> +1 (813) 597-9187</h1>
                  </a>
                </div>
              </a>
            </h1>
          </div>
        </div>
      </div>

      <div class="w-full mx-auto border-t border-white"></div>

      <div class="copyright py-5 max-w-7xl mx-auto px-4 sm:px-6 flex items-center justify-center md:justify-between lg:px-8">
        <div class="flex justify-center space-x-2 order-2">
          <h1 class="text-xl tracking-wide font-medium text-base text-gray-700 md:block hidden">
            <a class="hover:underline" href="/contact">
              Contact Us
            </a>
          </h1>
          <h1 class="text-xl tracking-wide font-medium text-base text-gray-700 md:block hidden">
            <a class="hover:underline" href="/Team">
              Team
            </a>
          </h1>

          <a
            href="https://www.facebook.com/Hyloq/"
            class="text-blue-500 hover:text-gray-700"
          >
            <span class="sr-only ">Facebook</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/company/hyloq"
            class="text-blue-500 hover:text-gray-700"
          >
            <span class="sr-only">Linkedin</span>
            <svg
              class="h-6 w-6 pt-0.5 pb-0.5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>

        <div class="mt-0 md:order-1">
          <p class="text-center md:text-base text-sm tracking-wide font-medium text-gray-700">
            &copy; {new Date().getFullYear()} Hyloq, LLC.
            <a
              class="text-primaryBlue hover:underline"
              href="/capabilities"
            ></a>{" "}
          </p>
        </div>
      </div>
    </footer>
  </div>
);

export default ThankYou;
